import React from "react";

class Footer extends React.Component<{}, {}> {
	public render() {
		return (
			<footer>
				Copyright © 1998–2025 Kathleen Kahn<br />
				Site created by <a href="https://mkps.app" target="_blank" rel="noreferrer">Madeline Kahn</a>
			</footer>
		);
	}
}

export default Footer;
