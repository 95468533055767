import React from "react";
import MyLink from "./MyLink";
import Nav from "./Nav";
import {ReactComponent as Logo} from "res/logo.svg";
import {FontAwesomeIcon as FA} from "@fortawesome/react-fontawesome";
import {faXmark, faBars} from "@fortawesome/free-solid-svg-icons";

interface HeaderState {
	showMenu: boolean;
}

class Header extends React.Component<{}, HeaderState> {
	private readonly fullNavMediaQuery: MediaQueryList = window.matchMedia("(min-width: 752px) and (min-height: 378px)");

	public constructor(props: {}) {
		super(props);
		this.state = {
			showMenu: false
		};

		this.handleEscapeKey = this.handleEscapeKey.bind(this);
		this.handleMediaChange = this.handleMediaChange.bind(this);
		this.toggleMenu = this.toggleMenu.bind(this);
	}

	public componentDidMount() {
		window.addEventListener("keydown", this.handleEscapeKey);
		this.fullNavMediaQuery.addEventListener("change", this.handleMediaChange);
	}

	public componentWillUnmount() {
		window.removeEventListener("keydown", this.handleEscapeKey);
		this.fullNavMediaQuery.removeEventListener("change", this.handleMediaChange);
	}

	private handleEscapeKey(e: KeyboardEvent): void {
		if (e.key == "Escape") {this.setMenu(false);}
	}

	private handleMediaChange(e: MediaQueryListEvent): void {
		if (e.matches) {this.setMenu(false);}
	}

	private toggleMenu(): void {
		this.setState({showMenu: !this.state.showMenu});
	}

	private setMenu(doShow: boolean): void {
		this.setState({showMenu: doShow});
	}

	public render() {
		return (
			<div id="headerContainer">
				<header>
					<MyLink to="/" onClick={() => {this.setMenu(false);}} id="logoLink" aria-label="Home"><Logo /></MyLink>
					<div className="headerSpacer"></div>
					<Nav />
					<button type="button" id="hamburgerButton" aria-label="Menu" onClick={this.toggleMenu}>
						{this.state.showMenu ? (
							<span key="1"><FA icon={faXmark} /></span>
						) : (
							<span key="0"><FA icon={faBars} /></span>
						)}
					</button>
				</header>
				<div id="hamburgerMenu" className={this.state.showMenu ? "show" : ""} aria-hidden={!this.state.showMenu}>
					<Nav hamburger isShown={this.state.showMenu} onLinkClick={() => {this.setMenu(false);}} />
				</div>
			</div>
		);
	}
}

export default Header;
