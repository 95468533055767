import React from "react";
import * as ContentData from "script/content-data";
import {FontAwesomeIcon as FA} from "@fortawesome/react-fontawesome";
import {faCaretUp, faCaretDown} from "@fortawesome/free-solid-svg-icons";
import withRouter, {RouterProp} from "script/with-router";
import MyLink from "./MyLink";

interface NavProps {
	hamburger?: boolean;
	isShown?: boolean;
	onLinkClick?: (e: MouseEvent) => void;
}

interface NavState {
	navData: NavData[];
	showSubmenuTitle: string | null;
	prevShown: boolean;
}

interface NavData {
	title: string;
	path: string;
	items?: NavData[];
}

class Nav extends React.Component<NavProps & RouterProp, NavState> {
	private readonly navRef: React.RefObject<HTMLElement>;

	public constructor(props: NavProps & RouterProp) {
		super(props);
		this.navRef = React.createRef();
		this.state = {
			navData: [],
			showSubmenuTitle: null,
			prevShown: false
		};

		this.handleClickForSubmenu = this.handleClickForSubmenu.bind(this);
	}

	public componentDidMount() {
		// create navdata
		this.setState({navData: [
			{
				title: "Home",
				path: "/"
			},
			{
				title: "Gallery",
				path: "/gallery",
				items: ContentData.categories.map((data) => {
					return {
						title: data.name,
						path: "/gallery/" + data.id
					};
				})
			},
			{
				title: "Exhibits",
				path: "/exhibits"
			},
			{
				title: "Artist Statement",
				path: "/statement"
			},
			{
				title: "About the Art",
				path: "/about"
			},
			{
				title: "Contact",
				path: "/contact"
			}
		]});

		// submenu button event
		document.addEventListener("click", this.handleClickForSubmenu);
	}

	public componentWillUnmount() {
		document.removeEventListener("click", this.handleClickForSubmenu);
	}

	public componentDidUpdate() {
		if (this.props.hamburger) {
			if (this.props.isShown != this.state.prevShown) {
				if (this.props.isShown) {
					// expand submenu of current page
					const item = this.state.navData.find((item) => {return item.items && this.pathStartsWith(item.path)});
					this.setState({showSubmenuTitle: (item ? item.title : null)});
				}
				this.setState({prevShown: this.props.isShown ?? false});
			}
		}
	}

	private handleClickForSubmenu(e: MouseEvent): void {
		let container;
		const targetEl = (e.target instanceof HTMLElement) ? e.target : null;
		if (targetEl?.closest(".navSubmenuContainer > button")) {
			if (this.navRef.current && Nav.isParent(this.navRef.current, targetEl)) {
				container = targetEl.closest(".navSubmenuContainer");
			}
		}
		if (container) {
			const title = container.getAttribute("data-title");
			this.setState({showSubmenuTitle: ((this.state.showSubmenuTitle == title) ? null : title)});
		} else {
			if (!this.props.hamburger) {this.setState({showSubmenuTitle: null});}
		}
	}

	private pathStartsWith(subpath: string): boolean {
		return this.props.router.location.pathname.indexOf(subpath) == 0;
	}

	private generateNavElements(navData: NavData[], tabIndex: number): JSX.Element[] {
		return navData.map((data) => {
			if (data.items) {
				const open = this.state.showSubmenuTitle == data.title;
				const isActive = this.pathStartsWith(data.path);
				return (
					<div data-title={data.title} className="navSubmenuContainer" key={data.title}>
						<button type="button" className={isActive ? "active" : ""} tabIndex={tabIndex}>{data.title}{open ? (<span key="1"><FA icon={faCaretUp} /></span>) : (<span key="0"><FA icon={faCaretDown} /></span>)}</button>
						<div className="navSubmenu" style={{display: open ? "block" : "none"}}>{this.generateNavElements(data.items, tabIndex)}</div>
					</div>
				);
			} else {
				return (<MyLink isNav onClick={this.props.onLinkClick} to={data.path} tabIndex={tabIndex} key={data.title}>{data.title}</MyLink>);
			}
		});
	}

	private static isParent(parent: HTMLElement, child: HTMLElement): boolean {
		let current: HTMLElement | null = child;
		while (current) {
			if (current == parent) {return true;}
			current = current.parentElement;
		}
		return false;
	}

	public render() {
		return (
			<nav ref={this.navRef}>
				{this.generateNavElements(this.state.navData, (this.props.hamburger && !this.props.isShown) ? -1 : 0)}
			</nav>
		);
	}
}

export default withRouter(Nav);
