import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Page from "./Page";
import ErrorPage from "./ErrorPage";

const Home = React.lazy(() => import('./Home'));
const Gallery = React.lazy(() => import('./Gallery'));
const Exhibits = React.lazy(() => import('./Exhibits'));
const Statement = React.lazy(() => import('./Statement'));
const About = React.lazy(() => import('./About'));
const Contact = React.lazy(() => import('./Contact'));

class App extends React.Component<{}, {}> {
	public render() {
		return (
			<BrowserRouter>
				<Routes>
					<Route path="/" element={(<Page />)}>
						<Route index element={(<Home />)} />
						<Route path="gallery">
							<Route path=":category" element={(<Gallery />)} />
							<Route index element={(<ErrorPage />)} />
						</Route>
						<Route path="exhibits" element={(<Exhibits />)} />
						<Route path="statement" element={(<Statement />)} />
						<Route path="about" element={(<About />)} />
						<Route path="contact" element={(<Contact />)} />
						<Route path="*" element={(<ErrorPage />)} />
					</Route>
				</Routes>
			</BrowserRouter>
		);
	}
}

export default App;
