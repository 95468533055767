import React from "react";
import ReactDOM from "react-dom/client";
import "style/index.css";
import "style/home.css";
import "style/gallery.css";
import "style/exhibits.css";
import "style/textPage.css";
import HoverClassManager from "script/hover-class";
import App from "components/App";

new HoverClassManager();
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
